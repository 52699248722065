import * as React from 'react';
import { Link } from 'gatsby';

type Item = {
  readonly label: string;
  readonly url: string;
};

type Props = {
  readonly items?: readonly Item[];
};

const itemClassName =
  'p-4 transition-colors duration-300 hover:text-black hover:underline focus:text-black focus:underline';

export const Breadcrumbs = ({ items = [] }: Props) => (
  <div className="flex items-center text-gray-48 leading-5 my-4 -mx-4">
    <Link to="/" className={itemClassName}>
      Главная
    </Link>
    {items.length > 0 && <span>|</span>}
    {items.map((item, i) => (
      <React.Fragment key={item.label}>
        {i !== 0 && <span>|</span>}
        <Link to={item.url} className={itemClassName}>
          {item.label}
        </Link>
      </React.Fragment>
    ))}
  </div>
);
