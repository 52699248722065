import * as React from 'react';
import classnames from 'classnames';
import { Price } from './Price';

type Props = {
  readonly minSum: string;
  readonly bonus: string;
  readonly replenishing: string;
  readonly className: string;
};

export const DepositBonuses = ({ minSum, bonus, replenishing, className }: Props) => (
  <div className={classnames('pc-row', className)}>
    <div className="text-gray-64 font-extrabold	leading-5 uppercase mb-8">Условия по депозиту</div>
    <div className="flex">
      <div className="w-1/3 text-black bg-gray-97 rounded-lg p-8 mr-4">
        <div className="text-xl leading-6 mb-4">Минимальная сумма</div>
        <Price value={parseInt(minSum, 10)} className="text-3xl font-bold leading-9" />
      </div>
      <div className="w-1/2 text-black bg-gray-97 rounded-lg p-8 mr-4">
        <div className="text-xl leading-6 mb-4">Бонус за депозит</div>
        <div className="text-3xl font-bold leading-9">{bonus}</div>
      </div>
      <div className="w-1/2 text-orange border border-orange rounded-lg p-8">
        <div className="text-xl leading-6 mb-4">При пополнении</div>
        <div className="text-3xl font-bold leading-9">{replenishing}</div>
      </div>
    </div>
  </div>
);
