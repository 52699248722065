import React from 'react';
import classnames from 'classnames';

let loaded = false;

const loadScript = (src: string, callback: () => void) => {
  if (loaded) {
    callback();
    return;
  }
  loaded = true;

  const s = document.createElement('script');
  s.type = 'text/javascript';
  s.src = src;
  s.onload = () => {
    callback();
  };

  const t = document.getElementsByTagName('script')[0];
  t.parentNode!.insertBefore(s, t);
};

type Props = {
  readonly onLoad: (map: ymaps.Map, ymaps: any) => void;
  readonly center: {
    readonly lat: number;
    readonly lng: number;
  };
  readonly className: string;
};

export const YandexMap = ({ onLoad, center, className }: Props) => {
  const onReady = () => {
    const { ymaps } = window;

    // eslint-disable-next-line func-names
    ymaps.ready(function () {
      const map = new ymaps.Map(
        'map',
        {
          center: [center.lat, center.lng],
          zoom: 18,
          controls: [],
        },
        {
          suppressMapOpenBlock: true,
        },
      );

      onLoad(map, ymaps);
    });
  };

  React.useEffect(() => {
    loadScript('https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=7e9fde79-9e7d-417f-8405-6025a5021b24', onReady);
  }, []);

  return <div id="map" className={classnames('w-full', className)} />;
};
