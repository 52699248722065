/* eslint-disable @typescript-eslint/no-use-before-define */
import * as React from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';
import { Layout } from 'components/Layout';
import { MessageForm } from 'components/MessageForm';
import { SEO } from 'components/SEO';
import { Breadcrumbs } from 'components/Breadcrumbs';
import { DeliveryMap } from 'components/DeliveryMap';
import { DeliveryCosts } from 'components/DeliveryCosts';
import { DepositBonuses } from 'components/DepositBonuses';

type Props = {
  readonly data: GatsbyTypes.BlockPageQuery;
};

/* eslint-disable @typescript-eslint/indent */

type Block =
  | {
      readonly type: 'p';
      readonly content: string;
      readonly color?: string;
    }
  | {
      readonly type: 'ordereditem';
      readonly blocks: Block[];
    }
  | {
      readonly type: 'orderedlist';
      readonly blocks: Block[];
    }
  | {
      readonly type: 'faqlist';
      readonly blocks: Block[];
    }
  | {
      readonly type: 'faqitem';
      readonly question: string;
      readonly answer: string;
    }
  | {
      readonly type: 'title';
      readonly value: string;
    }
  | {
      readonly type: 'deliveryMap';
    }
  | {
      readonly type: 'deliveryCosts';
    }
  | {
      readonly type: 'depositBonuses';
      readonly minSum: string;
      readonly bonus: string;
      readonly replenishing: string;
    }
  | {
      readonly type: 'header';
      readonly size: string;
      readonly value: string;
    };

const views: { [key: string]: (data: Block, index: number) => React.ReactNode } = {
  title: ({ value }: any, index) => (
    <React.Fragment key={index}>
      <SEO title={value} />
      <div className="pc-row mb-8">
        <Breadcrumbs />
        <h1 className="text-black text-5xl font-bold leading-none">{value}</h1>
      </div>
    </React.Fragment>
  ),
  p: ({ content, color }: any, index) => (
    <p key={index} style={{ color }} className="pc-row text-black leading-5 mb-4">
      {content}
    </p>
  ),
  faqlist: ({ blocks }: any, index) => (
    <div key={index} className="text-black border-t border-gray-90 pt-8 mt-16">
      <div className="pc-row">
        <div className="text-3xl font-bold leading-9 mb-8">Частые вопросы (FAQ)</div>
        {renderBlocks(blocks, views)}
      </div>
    </div>
  ),
  faqitem: ({ question, answer }: any, index) => (
    <div key={index} className="bg-gray-97 rounded-lg p-6 mb-4">
      <div className="text-xl font-bold leading-6 mb-4">{question}</div>
      <div className="leading-5">{answer}</div>
    </div>
  ),
  deliveryMap: (_, index) => <DeliveryMap key={index} className="my-12" />,
  deliveryCosts: (_, index) => <DeliveryCosts key={index} className="my-12" />,
  depositBonuses: ({ minSum, bonus, replenishing }: any, index) => (
    <DepositBonuses key={index} minSum={minSum} bonus={bonus} replenishing={replenishing} className="my-12" />
  ),
  header: ({ size, value }: any, index) => {
    const cl = classnames({
      'pc-row text-black font-bold leading-6 mt-12 mb-8': true,
      'text-2xl': size === '1',
      'text-xl': size === '2',
      'text-lg': size === '3',
      'text-base': size === '4',
      'text-sm': size === '5',
      'text-xs': size === '6',
    });

    return (
      <div key={index} className={cl}>
        {value}
      </div>
    );
  },
  orderedlist: ({ blocks }: any, index) => (
    <ul key={index} className="pc-row list-disc pl-4">
      {renderBlocks(blocks, views)}
    </ul>
  ),
  ordereditem: ({ blocks }: any, index) => <li key={index}>{renderBlocks(blocks, views)}</li>,
};

function renderBlocks(blocks: Block[] | null, currentViews: typeof views) {
  if (!blocks) {
    return null;
  }

  return blocks.map((block, index) => {
    if (currentViews[block.type]) {
      return currentViews[block.type](block, index);
    }

    if (process.env.NODE_ENV !== 'production') {
      return (
        <div className="text-2xl my-6" style={{ color: 'blue' }}>
          Unexpected type {block.type}
        </div>
      );
    }

    return null;
  });
}

const BlockPage = ({ data }: Props) => {
  const { block } = data.api;

  if (block) {
    const blockData: Block[] = JSON.parse(block.data);

    return (
      <Layout>
        <div className={classnames(block.name === 'privacy' && 'pb-12')}>{renderBlocks(blockData, views)}</div>
        <MessageForm className="mt-16" />
      </Layout>
    );
  }

  return null;
};

export const query = graphql`
  query BlockPage($name: String!) {
    api {
      block(name: $name) {
        name
        data
      }
    }
  }
`;

export default BlockPage;
