import * as React from 'react';
import classnames from 'classnames';
import { useForm } from 'react-hook-form';
import * as Sentry from '@sentry/browser';
import { validateEmail } from 'utils';
import { useMessageFormMutation, CreateMessageInput } from '__generated__/urql';
import { useGlobal } from './hooks';
import { TextField } from './TextField';
import { Loading } from './Loading';

type Props = {
  readonly className?: string;
};

export const MessageForm = ({ className }: Props) => {
  const [globalState] = useGlobal();
  const [mutationState, mutate] = useMessageFormMutation();
  const { handleSubmit, register, errors, reset } = useForm<CreateMessageInput>();
  const [errorMessage, setErrorMessage] = React.useState('');

  const onSubmit = handleSubmit(async (input) => {
    try {
      const res = await mutate({ token: globalState.token, input });

      setErrorMessage('');

      if (res.data && res.data.result && res.data.result.message) {
        setErrorMessage(res.data.result.message);
        Sentry.captureException(res.data.result.message);
      } else {
        reset();
      }
    } catch (e) {
      setErrorMessage('Произошла внутренняя ошибка');
      Sentry.captureException(e);
    }
  });

  return (
    <form onSubmit={onSubmit} className={classnames('text-black border-t border-b border-gray-90 py-12', className)}>
      <div className="pc-row">
        <div className="text-3xl font-bold leading-9 mb-8">Остались вопросы?</div>
        <div className="leading-5 mb-8">Если вы не нашли ответ на свой вопрос, опишите его здесь.</div>
        {!globalState.user && (
          <>
            <TextField
              name="name"
              register={register({ required: 'Введите ваше имя' })}
              label="Имя *"
              error={errors.name}
              placeholder="Как к вам обращаться?"
              className="mb-4"
            />
            <TextField
              name="email"
              register={register({ validate: validateEmail })}
              label="Email *"
              error={errors.email}
              placeholder="Введите ваш email"
              className="mb-4"
            />
          </>
        )}
        <TextField
          name="content"
          register={register({ required: 'Введите вопрос' })}
          label="Вопрос *"
          error={errors.content}
          placeholder="Опишите ваш вопрос здесь"
          Tag="textarea"
          className="mb-8"
        />
        {errorMessage && <div className="text-red mb-8">{errorMessage}</div>}
        <button type="submit" className="button button-primary button-large w-64">
          Отправить вопрос
        </button>
      </div>
      {mutationState.fetching && <Loading />}
    </form>
  );
};

export const mutation = /* urqlGraphQL */ `
  mutation MessageForm($token: String, $input: CreateMessageInput!) {
    result: createMessage(token: $token, input: $input) {
      message
    }
  }
`;
