import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import classnames from 'classnames';
import { YandexMap } from './YandexMap';
import s from './DeliveryMap.module.css';

type Props = {
  readonly className: string;
};

export const DeliveryMap = ({ className }: Props) => {
  const data = useStaticQuery<GatsbyTypes.DeliveryMapQuery>(
    graphql`
      query DeliveryMap {
        api {
          deliveryZones {
            bound {
              lat
              lng
            }
            deliveryCosts {
              itemSum
              value
            }
            mapFillColor
            mapStrokeColor
          }
        }
      }
    `,
  );

  const onLoad = (map: ymaps.Map, ymaps: any) => {
    map.setZoom(9);

    data.api.deliveryZones.forEach((z) => {
      const coords = z.bound.map((l) => [l.lat, l.lng]);
      const deliveryCost = z.deliveryCosts[0];

      const polygon = new ymaps.Polygon(
        [coords],
        {
          balloonContent: `от ${deliveryCost.itemSum}р, доставка ${deliveryCost.value}р`,
        },
        {
          fillColor: z.mapFillColor,
          strokeColor: z.mapStrokeColor,
          strokeWidth: 8,
        },
      );

      map.geoObjects.add(polygon);
    });
  };

  return (
    <div className={classnames('pc-row border-t border-b border-gray-90 py-12', className)}>
      <div className="text-black text-3xl font-bold leading-9 mb-8">Зона освоенная нашими курьерами</div>
      <YandexMap center={{ lat: 55.73473112282035, lng: 37.43875681368813 }} onLoad={onLoad} className={s.map} />
    </div>
  );
};
