import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import classnames from 'classnames';
import { Price } from './Price';

type Props = {
  readonly className: string;
};

export const DeliveryCosts = ({ className }: Props) => {
  const data = useStaticQuery<GatsbyTypes.DeliveryCostsQuery>(
    graphql`
      query DeliveryCosts {
        api {
          deliveryZones {
            deliveryCosts {
              value
            }
            mapFillColor
            mapStrokeColor
          }
          deliveryCosts {
            itemSum
          }
        }
      }
    `,
  );

  return (
    <div className={classnames('pc-row', className)}>
      <div className="text-gray-64 font-extrabold leading-5 uppercase mb-8">Стоимость доставки</div>
      <div className="flex mb-8">
        {data.api.deliveryZones.map((z, i) => (
          <div key={z.mapFillColor} className="w-1/4 relative text-black bg-gray-97 rounded-lg p-8 mr-4">
            <div
              className="absolute w-6 h-6 border-4 rounded-full"
              style={{ right: 16, top: 16, backgroundColor: z.mapFillColor, borderColor: z.mapStrokeColor }}
            />
            <div className="text-xl leading-6 mb-4">
              Зона {i + 1} <span className="text-orange font-bold">*</span>
            </div>
            <Price value={z.deliveryCosts[0].value} className="text-3xl font-bold leading-9" />
          </div>
        ))}
        <div className="w-1/2 text-orange border border-orange rounded-lg p-8">
          <div className="text-lg leading-6 mb-4">
            При заказе от <Price value={data.api.deliveryCosts[1].itemSum} />
          </div>
          <div className="text-3xl font-bold leading-9">Бесплатно</div>
        </div>
      </div>
      <div className="text-black leading-5">
        <span className="text-orange font-bold">*</span>{' '}
        <Price value={data.api.deliveryCosts[0].itemSum} className="font-bold" /> — сумма минимального заказа для
        осуществления доставки (с зонами 1 и 2 вы можете ознакомиться на карте ниже)
      </div>
    </div>
  );
};
